<template>
  <div>
    <!--   table列表     -->
    <div class='default-table' v-if='isXhsPlatform'>
      <ApeTable ref='apeTable'
                :data='selectDept'
                :columns='colsSet.xiaohongshu'
                :loading='loadingStatus'
                :pagingData='pagingData'
                :scroll-to-top='false'
                @switchPaging='switchPaging'
                @changeTableSort='changeTableSort'
                :summary-method='getSummaries'
                show-summary
                highlight-current-row
                border>
        <el-table-column prop='nickname' label='红人昵称' min-width='120' align='center' show-overflow-tooltip
                         slot='first-column' sortable='custom'>
          <template slot-scope='scope'>
            <artist-nickname :info='scope.row' />
          </template>
        </el-table-column>
        <el-table-column prop='department.name' label='部门' min-width='120' align='center'
                         slot='second-column' show-overflow-tooltip></el-table-column>
      </ApeTable>
    </div>
    <div class='default-table' v-else>
      <ApeTable ref='apeTable'
                :data='selectDept'
                :columns='colsSet.custom'
                :loading='loadingStatus'
                :pagingData='pagingData'
                :scroll-to-top='false'
                @switchPaging='switchPaging'
                @changeTableSort='changeTableSort'
                :summary-method='getSummaries'
                show-summary
                highlight-current-row
                border>
        <el-table-column prop='nickname' label='红人昵称' min-width='120' align='center' show-overflow-tooltip
                         slot='first-column' sortable='custom'>
          <template slot-scope='scope'>
            <artist-nickname :info='scope.row' />
          </template>
        </el-table-column>
        <el-table-column prop='department.name' label='部门' min-width='120' align='center'
                         slot='second-column' show-overflow-tooltip></el-table-column>
      </ApeTable>
    </div>

  </div>
  <!--  预约直播人数、直播预约进房、观看人数、支付人数、客单价、支付金额、支付转化率、封面点击率、停留时长、直播时长、互动率、粉丝成交人数、粉丝成交金额-->
</template>

<script>
import ApeTable from '@/components/ApeTable'

export default {
  name: 'LiveDataTable',
  components: {
    ApeTable
  },
  props: {
    // searchCondition: {
    //   type: Object,
    //   default() {
    //     return {
    //       platform_code: null,
    //       special_type: null,
    //       special_venue: null,
    //       time_value: [],
    //       artist_id: null
    //     }
    //   }
    // },
    groupId: {
      type: [Number, String]
    },
    liveType: {
      type: [Number, String],
      default() {
        return 1
      }

    }
  },
  computed: {
    selectDept() {
      let data = this.dataList
      if ((this?.groupId || null) != null) {
        let newArr = data.filter((item) => {
          return item.dpt_id == this.groupId
        })
        return newArr
      } else return this.dataList

    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler(val) {
        this.isXhsPlatform = val.platform_code === 'xiaohongshu'
        this.$refs['apeTable'].handleCurrentChange(1)//修改筛选条件后 从第一页开始筛选
      }
    }
  },
  mounted() {
    this.getColumns()
  },
  data() {
    return {
      loadingStatus: false,
      searchCondition: {},
      // 表格列表数据
      dataList: [
        // {
        //   rank: 1,
        //   live_time: '6小时',
        //   nickname: 'JJG',
        //   num_visitor: 30000,
        //   max_online: 13000,
        //   avg_view_time: '10小时',
        //   num_goods_clicks: 34000,
        //   num_payed_orders: 100,
        //   amount: 690000,
        //   c_unit_price: 1000,
        //   num_goods: 10,
        //   num_follow_add: 10,
        //   rate_follow: 10,//转粉率
        //   val_uv: 1000,
        //   rate_payment: 10,
        //   amount_live: 690000,//直播投放金额
        // }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      dayRange: [
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
        { label: '近90天', value: 90 }
      ],
      orderSort: { live_time: 'desc', id: 'desc' },//默认排序规则
      isXhsPlatform: false,
      colsSet: []
    }
  },
  methods: {
    show(query) {
      console.log(query)
      this.searchCondition = query
    },
    async getColumns() {
      // getLiveInfoColSet
      let { colsSet } = await this.$api.getLiveInfoColSet()
      this.colsSet = colsSet
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 1) {
          return
        }
        if (index === 1) {
          sums[index] = '合计:'
          return
        }
        if (['num_payed_orders', 'amount', 'amount_live', 'num_follow_add', 'commission_estimate', 'pit_fee'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'amount':
            case 'amount_live':
            case 'commission_estimate':
            case 'pit_fee':
              sums[index] = this.moneyFormat(sums[index])
              break
            default:
              sums[index] = this.numFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    rateFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getLiveInfoList(searchCondition)
      this.dataList = list
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'time_value' && this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      condition.live_type = this.liveType
      return condition
    }
  }
}
</script>

<style scoped>

</style>
