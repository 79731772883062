<template>
  <div>
    <el-row class='table-header'>
      <el-col :span='20'>
        <platforms-radio-group :showAll='true' :platformCode='searchCondition.platform_code'
                               @choicePlatform='choicePlatform'
                               @getPlatformsRange='getPlatformsRange'></platforms-radio-group>
      </el-col>
    </el-row>
    <el-row style='margin-top: 8px'>
      <el-col :span='24'>
        <el-form ref='searchForm' size='mini' :inline='true' :model='searchCondition'>
          <el-form-item label=''>
            <el-radio-group v-model='checkedRadio' @change='radioChange'>
              <el-radio-button :label='option.value' v-for='(option,index) in dayRange' :key='index'>{{
                  option.label
                }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              v-model='ym'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月份' style='width: 130px' @change='changeMonth'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              style='width: 280px'
              v-model='searchCondition.time_value'
              type='daterange'
              unlink-panels
              range-separator='至'
              start-placeholder='开始日期'
              end-placeholder='结束日期'
              format='yyyy年MM月dd日'
              value-format='yyyy-MM-dd' @change='changeTime'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label='' prop='group_id'>
            <CompanySelect :multiple='true' @handleSelect='handleDept'></CompanySelect>
            <!--            <el-select v-model='groupId' placeholder='请选择' clearable>-->
            <!--              <el-option-->
            <!--                v-for='item in GroupDeptOption'-->
            <!--                :key='item.name'-->
            <!--                :label='item.name'-->
            <!--                :value='item.id'>-->
            <!--              </el-option>-->
            <!--            </el-select>-->
          </el-form-item>
          <el-form-item>
            <artist-search @handleSelect='chooseArtist' size='small' />
          </el-form-item>
          <el-form-item>
            <div style='display: flex;flex-direction: row;gap: 5px'>
              <el-select v-model='searchCondition.special_type' placeholder='是否专场' style='width: 140px'
                         @change='handleQuery' clearable>
                <el-option label='全部' value='all'></el-option>
                <el-option label='是' value='Y'></el-option>
                <el-option label='否' value='N'></el-option>
              </el-select>
              <el-input v-if='searchCondition.special_type==="Y"' v-model='searchCondition.special_venue'
                        placeholder='专场检索：输入专场关键词' clearable />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
            <el-button type='default' icon='el-icon-data-line' @click='showChart'>汇总</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!--    汇总数据图表-->
    <ChartPanel :live-type='liveType' ref='refChartPanel' :platforms='platforms'></ChartPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import ArtistSearch from '@/components/artist/ArtistSearch'
import ChartPanel from '@/pages/live_data/ChartPanel'
import moment from 'moment'

export default {
  name: 'SearchTool',
  components: { ChartPanel, ArtistSearch, PlatformsRadioGroup },
  props: {
    liveType: {
      type: [Number, Number]

    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      checkedRadio: null,
      dayRange: [
        { label: '当月', value: 'current_month' },
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
        { label: '近90天', value: 90 }
      ],
      ym: null,
      searchCondition: {
        platform_code: null,
        time_value: [],
        artist_id: null,
        special_venue: null,
        special_type: null,
        group_ids: null
      },
      fileTitle: '',
      orderSort: { live_time: 'desc', id: 'desc' },//默认排序规则
      GroupDeptOption: [],
      groupId: ''
    }
  },
  created() {

  },
  mounted() {
    this.defaultDate()
  },
  methods: {
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    //获取平台的范围数据
    getPlatformsRange(val) {
      this.platforms = val
    },
    choiceTimeData() {
      const value = this.checkedRadio
      let beginTime, endTime
      switch (value) {
        case 'current_month':
          beginTime = moment().startOf('month').format('YYYY-MM-DD')
          endTime = moment().endOf('month').format('YYYY-MM-DD')
          break
        default:
          beginTime = this.getLastDays(value)
          endTime = this.getLastDays(1)
          break
      }
      //选择时间
      this.searchCondition.time_value = [beginTime, endTime]
      this.handleQuery()
    },
    radioChange() {
      this.choiceTimeData()
    },
    defaultDate() {
      this.checkedRadio = 'current_month'
      // 默认选择
      this.choiceTimeData()
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val
      this.handleQuery()
    },
    changeTime() {
      this.ym = null
      this.checkedRadio = null
      this.handleQuery()
    },
    changeMonth() {
      this.checkedRadio = null
      if (this.ym) {
        let ymArr = this.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天

        this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]
      } else {
        this.searchCondition.time_value = []
      }
      this.handleQuery()
    },
    handleDept(val) {
      // console.log(val)
      if (val && val.length > 0)
        this.searchCondition.group_ids = val
      else
        this.searchCondition.group_ids = null
    },
    chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
      this.handleQuery()
    },
    handleQuery() {
      this.$emit('handleQuery', this.searchCondition)
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'time_value' && this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }
      return condition
    },
    showChart() {
      this.$refs['refChartPanel'].show(this.handleSearchCondition())
    }
  }
}
</script>

<style scoped>

</style>
