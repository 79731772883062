<template>
  <el-dialog :title='title'
             :visible.sync='dialogVisible'
             width='800px'>
    <div class='search-box' @click='getList'>
      <span class='label'> 平台：</span>{{ searchCondition.platform_name || '全平台' }}
      <span class='label'> 时间区间：</span>{{ $utils.parseTime(searchCondition.start_time, '{y}年{m}月{d}日') }} ~
      {{ $utils.parseTime(searchCondition.end_time, '{y}年{m}月{d}日') }}
      <span
        class='label'> 是否专场：</span>{{ searchCondition.special_type ? (searchCondition.special_type == 'Y' ? '是' : '否') : '不限'
      }}
    </div>
    <div class='default-table' style='width: 90%;min-width: 400px;max-width: 100%;padding: 10px;margin: auto'>
      <div style='margin-bottom: 8px'>
        <span class='label'>红人：</span><span class='num-val'>{{ summary.kol_sum || 0 }}位</span>
        <span class='label'>场次合计：</span><span class='num-val'>{{ summary.count_sum || 0 }} 场</span>
        <span class='label'>GMV合计：</span><span class='num-val money'>{{ $utils.numberFormat(summary.gmv_sum || 0, 2)
        }}</span>
      </div>
      <el-table :data='dataList' max-height='800px'>
        <el-table-column label='序号' type='index' width='88' align='center'></el-table-column>
        <el-table-column label='红人昵称' prop='nickname' min-width='120' align='center' sortable></el-table-column>
        <el-table-column label='直播场次' prop='count' min-width='100' align='center' sortable></el-table-column>
        <el-table-column label='gmv汇总' prop='gmv' min-width='120' header-align='right' align='right' sortable
                         :default-sort="{prop: 'gmv', order: 'descending'}">
          <template slot-scope='{row}'>
            <span class='money'>{{ $utils.numberFormat(row.gmv, 2) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: 'ChartPanel',
  props: {
    liveType: {
      type: [Number, Number]

    },
    platforms: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      title: '直播数据汇总图表',
      dialogVisible: false,
      searchCondition: {},
      dataList: [
        // { nickname: 'XXX 红人', count: 10, gmv: 1000 }
      ],
      summary: { kol_sum: 0, count_sum: 0, gmv_sum: 0 }
    }
  },
  methods: {
    show(searchCondition) {
      this.dialogVisible = true
      this.title = `直播(${this.liveType == 1 ? '达播' : '日播'})数据汇总`

      this.searchCondition = { ...searchCondition }
      this.searchCondition.live_type = this.liveType
      this.calcPlatformName()
      this.getList()
    },
    async getList() {
      let { list, summary } = await this.$api.getLiveInfoSummary(this.searchCondition)
      this.dataList = list || []
      this.summary = summary

    },
    calcPlatformName() {
      if (this.searchCondition.platform_code) {
        let platformObj = this.platforms.find(value => value.code === this.searchCondition.platform_code)

        this.searchCondition.platform_name = platformObj.name || ''
      }

    }
  }
}
</script>

<style scoped>
.label {
  font-weight: bold;
  margin: 10px;
  font-size: 1.1em;
}

.num-val {
  color: #fe346e;

}

.search-box {
  border: #e97ca1 1px dashed;
  border-radius: 20px;
  line-height: 30px;
  padding: 5px 20px;
}
</style>
